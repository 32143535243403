@import "bootstrap/bootstrap";

#masthead {
  height: 100vh;
  min-height: 400px;
  background-image: url('/images/heading_low.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #333;
  z-index: -1;
}

#heading-text {
  color: white;
  font-display: auto;
  font-family: 'Righteous', sans;
  text-shadow: 3px 3px 3px rgba(0,0,0,0.5);
}

.subtext {
  color: white;
  text-shadow: 3px 3px 3px rgba(0,0,0,0.8);
}

#findme {
  min-height: 500px;
}

#particles-js canvas{
    position: absolute;
    width: 100%;
    height: 100%;
}

#footer {
  position: fixed;
  width: 100%;
  height: 6rem;
  left: 0;
  bottom: 0;
}

#footer a {
  color: white;
  font-size: 3rem;
}